import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';

import {StyleObject,  MenuItemId} from '../../../types/types'
import useDeviceDetect from '../../helpers/useDetectDevice';
import useStaticImage from '../../helpers/useStaticImage';

const STYLES: StyleObject = {
  container: {
    width: '345px',
  },
  media: {
    height: '160px',
  },
  titleSection: {display: 'flex', justifyContent: 'center'},
};

interface Props {
  title: string,
  image: MenuItemId;
  onClick: () => void;
}

export default function HomeCard(props: Props): JSX.Element {
  const { title, image, onClick } = props;

  const breadHamData = useStaticImage(image);
  const { isMobile } = useDeviceDetect();

  const cardStyles = isMobile ? { width: '99%' } : STYLES.container;

  return (
    <Grid item style={cardStyles}>
      <Card elevation={3} onClick={onClick}>
        <CardActionArea>
          <CardMedia
            component="img"
            style={STYLES.media}
            image={breadHamData.src}
            title={title}
            className="home-img"
          />
          <CardContent>
            <span style={STYLES.titleSection}>
              {title}
            </span>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
