import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { StyleObject } from '../../../../types/types';
import useDetectDevice from '../../../helpers/useDetectDevice';

const STYLES: StyleObject = {
  cardMobile: { minWidth: '99%' },
  cardDesk: {},

  title: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: 'rgb(142 136 136)',
    borderRadius: '5px 5px 0px 0px',
    color: 'white',
    fontFamily: 'Antojitography',
    fontSize: 'xx-large',
  },
}

interface Props {
  title: string;
  children: JSX.Element;
}

export default function CheckoutCard(props: Props): JSX.Element {
  const { title, children } = props;
  const { isMobile } = useDetectDevice();

  const cardStyles = isMobile ? STYLES.cardMobile : STYLES.cardDesk;

  return (
    <Grid item xs={6} style={cardStyles}>
      <Paper style={STYLES.paper}>
        <span style={STYLES.title}>{title}</span>
        {children}
      </Paper>
    </Grid>
  );
}
