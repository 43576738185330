import React, { useState, ChangeEvent, CSSProperties } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import {StyleObject, MenuItemProps, CartItemProps} from '../../../types/types'
import useDeviceDetect from '../../helpers/useDetectDevice';
import useStaticImage from '../../helpers/useStaticImage';
import {formatMoney} from '../../helpers/utils';
import { useCartDispatchers } from '../../reducers'
import AddIcon from '../icons/Add';
import RemoveIcon from '../icons/Remove';
import DeleteIcon from '../icons/Delete';


const STYLES: StyleObject = {
  container: {
    maxWidth: '345px',
    minWidth: '345px',
  },
  media: {
    height: '180px',
  },
  button: {
    marginLeft: 'auto',
  },
  addButton: {
    border: '1px #2FD46B solid',
    color: '#2FD46B'
  },
  removeButton: {
    border: '1px #f44336 solid',
    color: '#f44336'
  },
  titleSection: {display: 'flex'},
  price: {marginLeft: 'auto', alignSelf: 'center'},
  outlined: {padding: '2px', minWidth: '0px', borderRadius: '20px'}
};

function addButtonAdditionalStyles(IsAddDisabled: boolean) {
  return !IsAddDisabled ? STYLES.addButton : {};
}

function removeButtonAdditionalStyles(IsAddDisabled: boolean) {
  return !IsAddDisabled ? STYLES.removeButton : {};
}

interface OutlinedIconButton {
  Icon: JSX.Element;
  style?: CSSProperties;
  disabled: boolean;
  onClick: () => void;
}

function OutlinedIconButton(props: OutlinedIconButton) {
  return (
    <Button
      variant="outlined"
      size="small"
      color="primary"
      disabled={props.disabled}
      onClick={props.onClick}
      style={{ ...(props.style ?? {}), ...STYLES.outlined}}
    >
      {props.Icon}
    </Button>
  )
}

function getItemButton(
  isAdded: boolean,
  handleAddToCartClick: () => void,
  handleRemoveFromCartClick: () => void,
  IsAddDisabled: boolean,
): JSX.Element {
  return isAdded
    ? (
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        style={STYLES.button}
        startIcon={<DeleteIcon />}
        onClick={handleRemoveFromCartClick}
      >
        Remover
      </Button>
    ) : (
      <Button
        size="small"
        variant="outlined"
        style={{
          ...STYLES.button,
          ...addButtonAdditionalStyles(IsAddDisabled)
        }}
        startIcon={<AddIcon />}
        onClick={handleAddToCartClick}
        disabled={IsAddDisabled}
      >
        Agregar
      </Button>
    )
}

interface Props {
  item: MenuItemProps
  isAdded: boolean;
}

export default function MenuItem (props: Props): JSX.Element {
  const { item, isAdded } = props;
  const { title, description, id, price } = item;
  const [quantity, setQuantity] = useState('1');
  const { dispatchAddToCart, dispatchRemoveFromCart } = useCartDispatchers();

  const breadHamData = useStaticImage(id);
  const { isMobile } = useDeviceDetect();

  const cartItem: CartItemProps = {
    id: item.id,
    quantity: parseInt(quantity)
  }

  const handleAddToCartClick = (): void => {
    dispatchAddToCart(cartItem);
  };

  const handleRemoveFromCartClick = (): void => {
    setQuantity('1');
    dispatchRemoveFromCart(cartItem);
  };

  const setQuantityChange = (value: string): void => {
    if ((isFinite(parseInt(value)) && parseInt(value) > 0) || value === '') {
      setQuantity(value);
    }
  };

  const handleQuantityChange = (elem: ChangeEvent<HTMLInputElement>): void => {
    const { value } = elem.currentTarget;

    setQuantityChange(value);
  };

  const isAddDisabled = !(isFinite(parseInt(quantity)));

  const cardStyles = isMobile ? { maxWidth: '99%', minWidth: '99%' } : STYLES.container;

  return (
    <Grid item style={cardStyles}>
      <Card elevation={3}>
        <CardActionArea>
          {!isMobile && (
            <CardMedia
              style={STYLES.media}
              image={breadHamData.src}
              title={title}
            />
          )}
          <CardContent>
            <div style={STYLES.titleSection}>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography gutterBottom variant="body1" component="h5" style={STYLES.price}>
              {formatMoney(price)}
            </Typography>
            </div>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions style={STYLES.button}>
          {isMobile ? (
            <React.Fragment>
              <OutlinedIconButton
                Icon={<AddIcon />}
                disabled={isAdded}
                style={{...addButtonAdditionalStyles(isAdded)}}
                onClick={() => (setQuantityChange(String(parseInt(quantity || '0') + 1)))}
              />
              <TextField
                id="outlined-number"
                label="Cantidad"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={quantity}
                onChange={handleQuantityChange}
                size="small"
                disabled={isAdded}
                style={{width: '82px'}}
              />
              <OutlinedIconButton
                Icon={<RemoveIcon />}
                disabled={isAdded}
                style={{...removeButtonAdditionalStyles(isAdded)}}
                onClick={() => (setQuantityChange(String(parseInt(quantity || '2') - 1)))}
              />
            </React.Fragment>
          ) : (
            <TextField
             id="outlined-number"
             label="Cantidad"
             type="number"
             InputLabelProps={{
               shrink: true,
             }}
             variant="outlined"
             value={quantity}
             onChange={handleQuantityChange}
             size="small"
             disabled={isAdded}
             style={{width: '150px'}}
            />
          )}
          {getItemButton(isAdded, handleAddToCartClick, handleRemoveFromCartClick, isAddDisabled)}
        </CardActions>
      </Card>
    </Grid>
  );
}
