import React, { useState, SyntheticEvent, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';

import {StyleObject, MenuItemProps, MenuItemId} from '../../../types/types'
import { useAppCart } from '../../reducers';
import useDeviceDetect from '../../helpers/useDetectDevice';
import useAppMenu from '../../hooks/useAppMenu';
import ShoppingCartIcon from '../icons/ShoppingCart';
import MenuItem from './MenuItem';
import Snack from '../Snack';

const STYLES: StyleObject = {
  snackCart: {
    top: '85px',
    zIndex: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'fixed',
    left: 0,
    right: 0,
    backgroundColor: '#4caf50',
    textTransform: 'capitalize',
    width: '220px',
  },
  textContent: {
    margin: `0 auto`,
    maxWidth: '1025px',
    padding: `1.45rem 1.0875rem`,
  }
}

function menuItemMapper(item: MenuItemProps, cartItemIds: MenuItemId[]): JSX.Element {
  return (
    <MenuItem
      key={item.id}
      item={item}
      isAdded={cartItemIds.includes(item.id)}
    />
  );
}

interface Props {
  items: MenuItemProps[],
}

export default function MenuContent (props: Props): JSX.Element {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(true);
  const [isSnackCartOpen, setIsSnackCartOpen] = useState(false);
  const cart = useAppCart();
  const { setIsAppMenuOpen } = useAppMenu();
  const { isMobile } = useDeviceDetect();
  const { items } = props;
  const cartItemsIds: MenuItemId[] = cart.items.map((item) => item.id);
  const additionalStyles = isMobile ? {maxWidth: '95vw'} : {};

  const handleClose = (_event: SyntheticEvent, _reason: string) => {
    setIsSnackbarOpen(false);
  };

  const handleSnackCartClose = () => {
    setIsSnackbarOpen(false);
    setIsAppMenuOpen(true);
  };

  useEffect(() => {
    if (cart.items.length > 0 && !isSnackCartOpen) {
      setIsSnackCartOpen(true);
    }

    if (cart.items.length === 0 && isSnackCartOpen) {
      setIsSnackCartOpen(false);
    }
  }, [cart]);

  return (
    <React.Fragment>
      {isSnackbarOpen &&
        <React.Fragment>
          <Snack
            key="snack-1"
            isOpen={isSnackbarOpen}
            onClose={handleClose}
            style={{bottom: 80}}
            message="Los pedidos seran enviados a traves de WhatsApp"
          />
          <Snack
            key="snack-2"
            isOpen={isSnackbarOpen}
            onClose={handleClose}
            style={{bottom: 20}}
            message="Pedidos realizados con anticipacion"
          />
        </React.Fragment>
      }
      {isSnackCartOpen && (
        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          style={STYLES.snackCart}
          onClick={handleSnackCartClose}
        >
          <ShoppingCartIcon style={{marginRight: '5px'}} />
          Ver Resumen de Pedido
        </Fab>
      )}
      <Grid container style={{marginLeft: '10px', ...additionalStyles}} spacing={3}>
        {items.map((item) => menuItemMapper(item, cartItemsIds))}
      </Grid>
    </React.Fragment>
  );
}
