import React from 'react';
import Grid from '@material-ui/core/Grid';

import { MenuItemId } from '../../../types/types';
import useDeviceDetect from '../../helpers/useDetectDevice';
import {navigateToComboMenu, navigateToIndividualMenu} from '../../helpers/navigation';
import useOpenScreen from '../../hooks/useOpenScreen';
import HomeCard from './HomeCard';

export default function HomeScreen(): JSX.Element {
  const {setOpenScreen} = useOpenScreen();
  const { isMobile } = useDeviceDetect();

  const additionalStyles = isMobile ? {width: '95vw'} : {justifyContent: 'center'};

  const handeOpenIndividualMenu = (): void => {
    navigateToIndividualMenu(setOpenScreen);
  };

  const handeOpenComboMenu = (): void => {
    navigateToComboMenu(setOpenScreen);
  };

  return (
    <div style={{flex: 1}}>
      <div style={{padding: '0 20px 20px 20px'}}>
      <p style={{fontFamily: 'Antojitography', fontSize: 'xx-large', textAlign: 'center'}}>
        Ya son <b>cinco años</b> llevando el exquisito sabor navideño, toque caraqueño, para el disfrute familiar. Sea individual o en combo, lleva El mejor sabor a tu mesa.
      </p>
      </div>
      <Grid container style={{marginLeft: '10px', ...additionalStyles}} spacing={3}>
        <HomeCard
          title="Ir a Menu Individual"
          image={MenuItemId.MenuMain}
          onClick={handeOpenIndividualMenu}
        />
        <HomeCard
          title="Ir a Combos"
          image={MenuItemId.ComboMain}
          onClick={handeOpenComboMenu}
        />
      </Grid>
    </div>
  );
}
