import React from "react"

import Layout from "./Layout"
import MenuContent from './MenuContent';
import CheckoutScreen from './CheckoutScreen';
import HomeScreen from './HomeScreen';
import { INDIVIDUAL_MENU, COMBO_MENU } from '../constants'
import useOpenScreen from '../hooks/useOpenScreen';
import {Screen} from '../../types/types';
import SEO from "./seo"

function renderSiteContent(openScreen: Screen): JSX.Element {
  switch (openScreen) {
    case Screen.Checkout:
      return <CheckoutScreen />;
    case Screen.Home:
      return <HomeScreen />;

    case Screen.ComboMenu:
      return <MenuContent items={COMBO_MENU} />;
    case Screen.IndividualMenu:
    default:
      return <MenuContent items={INDIVIDUAL_MENU} />;
  }
}

export default function App(): JSX.Element {
  const {openScreen} = useOpenScreen();
  return (
    <Layout>
      <SEO title="Home" />
      {renderSiteContent(openScreen)}
    </Layout>
  );
}
