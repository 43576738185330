import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { CartItemProps, CartProps } from '../../../../types/types';
import { formatMoney, getMenuItem } from '../../../helpers/utils';

import CartMenuItem from '../../CartMenuItem';
import CheckoutCard from './CheckoutCard';

function getCartMenuMapper(item: CartItemProps): JSX.Element {
  return (
    <CartMenuItem
      key={item.id}
      menuItem={getMenuItem(item)}
      cartDetails={item}
      isExtended={true}
      addRemoveLink={true}
    />
  );
}

interface Props {
  cart: CartProps;
}

export default function CartMenuSection(props: Props): JSX.Element {
  const { cart } = props;
  const total = `Total ${formatMoney(cart.total)}`;

  return (
    <CheckoutCard title="Resumen de tu Pedido">
      <React.Fragment>
        <Grid container spacing={1} style={{padding: '20px 10px'}}>
          {cart.items.map(getCartMenuMapper)}
        </Grid>
        <Divider />
        <div style={{marginTop: '10px', marginRight: '10px', paddingBottom: '1px'}}>
          <Typography gutterBottom variant="h6" style={{textAlign: 'end'}}>
            {total}
          </Typography>
        </div>
      </React.Fragment>
    </CheckoutCard>
  );
}
