import { useEffect, useState } from "react";

const IS_MOBILE_REGEX = /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i;

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);
  const { navigator } = global.window ?? {};
  useEffect(() => {
    const userAgent = navigator?.userAgent ?? '';
    const matchMobile = Boolean(userAgent.match(IS_MOBILE_REGEX));
    setMobile(matchMobile);
  }, []);

  return { isMobile };
}