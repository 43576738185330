import "moment/locale/es";
import moment, { Moment } from "moment";
import React, { ChangeEvent } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";


import CheckoutCard from './CheckoutCard';
import { StyleObject } from '../../../../types/types';


const STYLES: StyleObject = {
  container: { display: 'flex', justifyContent: 'space-between' },
  first: {padding: '20px 15px 15px 15px'},
  rest: {padding: '10px 15px 15px 15px'}
};

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      500: '#723f5e'
    },
  },
});

export enum PickupOption {
  Delivery = 'Delivery',
  PickOnPlace = 'Recoger en Sitio',
}

export enum PaymentOption {
  Yappy = 'Yappy',
  Transference = 'Transferencia Bancaria',
  Cash = 'Efectivo',
}

interface Props {
  selectedDate: Moment;
  onDateChange: (selectedDate: Moment) => void;
  name: string;
  onNameChange: (name: string) => void;
  lastname: string;
  onLastnameChange: (lastname: string) => void;
  phoneNumber: string;
  onPhoneNumberChange: (lastname: string) => void;
  pickupOption: PickupOption,
  setPickupOption: (pickupOption: PickupOption) => void;
  paymentOption: PaymentOption,
  setPaymentOption: (paymentOption: PaymentOption) => void;
  hasFormErrors: boolean;
}

export default function CustomerSection(props: Props): JSX.Element {

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    props.onNameChange(value);
  };

  const handleLastnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    props.onLastnameChange(value);
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    props.onPhoneNumberChange(value);
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setPickupOption(event.target.value as PickupOption);
  };

  const handlePaymentChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.setPaymentOption(event.target.value as PaymentOption);
  };

  const handleDateChange = (date: MaterialUiPickersDate) => props.onDateChange(date as Moment);

  return (
    <CheckoutCard title="Datos del comprador">
      <React.Fragment>
        <Grid container spacing={1} style={{...STYLES.container, ...STYLES.first}}>
          <TextField
            error={props.hasFormErrors && !props.name}
            helperText={props.hasFormErrors && !props.name ? 'Campo Requerido' : ''}
            id="outlined-name"
            label="Nombre"
            variant="outlined"
            value={props.name}
            onChange={handleNameChange}
            style={{flex: 1, marginRight: '5px'}}
          />
          <TextField
            error={props.hasFormErrors && !props.lastname}
            helperText={props.hasFormErrors && !props.lastname ? 'Campo Requerido' : ''}
            id="outlined-lastname"
            label="Apellido"
            variant="outlined"
            value={props.lastname}
            onChange={handleLastnameChange}
            style={{flex: 1, marginLeft: '5px'}}
          />
        </Grid>
        <Grid container spacing={1} style={{...STYLES.rest, ...STYLES.container}}>
          <TextField
            id="outlined-phone"
            label="Otro Numero de Telefono"
            variant="outlined"
            style={{width: '100%'}}
            value={props.phoneNumber}
            onChange={handlePhoneChange}
          />
        </Grid>
        <Grid container spacing={1} style={{...STYLES.rest, ...STYLES.container}}>
          <RadioGroup aria-label="pickupOption" name="pickupOption" value={props.pickupOption} onChange={handleRadioChange}>
            <FormControlLabel value={PickupOption.Delivery} control={<Radio />} label="Delivery (Costo varia por zona)" />
            <FormControlLabel value={PickupOption.PickOnPlace} control={<Radio />} label="Recoger en Sitio (El Bosque - McDonalds)" />
          </RadioGroup>
        </Grid>
        <Grid container spacing={1} style={{...STYLES.rest, ...STYLES.container}}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="es">
              <DatePicker
                label="Fecha Tentativa de Delivery"
                disablePast
                value={props.selectedDate}
                onChange={handleDateChange}
                style={{width: '100%'}}
                animateYearScrolling
                TextFieldComponent={(textFieldProps) => (
                  <TextField
                    {...textFieldProps}
                    id="outlined-date"
                    variant="outlined"
                  />
                )}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Grid>
        <Grid container spacing={1} style={{...STYLES.rest, ...STYLES.container}}>
          <FormControl component="fieldset">
          <FormLabel component="legend">Forma de Pago</FormLabel>
          <RadioGroup aria-label="paymentOption" name="paymentOption" value={props.paymentOption} onChange={handlePaymentChange}>
            <FormControlLabel value={PaymentOption.Transference} control={<Radio />} label="Transferencia Bancaria" />
            <FormControlLabel value={PaymentOption.Yappy} control={<Radio />} label="Yappy - Banco General" />
            <FormControlLabel value={PaymentOption.Cash} control={<Radio />} label="Efectivo" />
          </RadioGroup>
          </FormControl>
        </Grid>
      </React.Fragment>
    </CheckoutCard>
  );
}
