import React, { SyntheticEvent, CSSProperties } from 'react';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Slide from '@material-ui/core/Slide';
import CheckCircleIcon from '../icons/CheckCircle';

interface Props {
  isOpen: boolean;
  onClose: (event: SyntheticEvent, reason: SnackbarCloseReason) => void;
  style: CSSProperties;
  message: string;
}

export default function Snack(props: Props): JSX.Element {
  const message = (
    <React.Fragment>
      <CheckCircleIcon style={{verticalAlign: 'middle'}}/>
      <span style={{verticalAlign: 'middle', marginLeft: '10px'}}>{props.message}</span>
    </React.Fragment>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={10000}
      open={props.isOpen}
      onClose={props.onClose}
      TransitionComponent={(slideProps) => <Slide {...slideProps} direction="up" />}
      key={'TransitionDown'}
      style={props.style}
    >
      <SnackbarContent
        style={{backgroundColor:'#4caf50'}}
        message={message}
      />
    </Snackbar>
  );
}
