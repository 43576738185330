import '../components/layout.css';
import React, {useReducer, useState, useEffect} from "react"
import App from "../components/App"
import Reducer, { ReducerContext } from '../reducers';
import { AppMenuContext } from '../hooks/useAppMenu';
import { OpenScreenContext } from '../hooks/useOpenScreen';
import { useOnPopStateEffect, useInitialRouteSetupEffect } from '../helpers/navigation'
import { Screen } from '../../types/types'

export default function IndexPage(): JSX.Element {
  const [state, dispatch] = useReducer(Reducer, {});
  const [isAppMenuOpen, setIsAppMenuOpen] = useState(false);
  const [openScreen, setOpenScreen] = useState(Screen.Home);

  useEffect(() => {
    window.localStorage.setItem('isAppMenuOpen', String(isAppMenuOpen));
  }, [isAppMenuOpen]);

  useInitialRouteSetupEffect();

  useOnPopStateEffect(setOpenScreen, setIsAppMenuOpen);

  return (
    <ReducerContext.Provider value={{ state, dispatch }}>
      <AppMenuContext.Provider value={{isAppMenuOpen, setIsAppMenuOpen}}>
        <OpenScreenContext.Provider value={{openScreen, setOpenScreen}}>
          <App />
        </OpenScreenContext.Provider>
      </AppMenuContext.Provider>
    </ReducerContext.Provider>
  );
}
