import { useStaticQuery, graphql } from "gatsby"
import { MenuItemId } from '../../types/types';

const STATIC_IMAGES = graphql`
    query {
      BreadHam: file(relativePath: { eq: "breadham.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      EmptyCart: file(relativePath: { eq: "empty_cart.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      EmptyCart2: file(relativePath: { eq: "empty_cart2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ComboMain: file(relativePath: { eq: "combo_main.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ChristmasDish: file(relativePath: { eq: "platonav.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      SmallPapayaSweet: file(relativePath: { eq: "dulcepeq.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      BigPapayaSweet: file(relativePath: { eq: "dulcegde.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      Hallacas: file(relativePath: { eq: "hallaca.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      Salad: file(relativePath: { eq: "salad.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      HallacasBun: file(relativePath: { eq: "bollitos.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      PorkRoast: file(relativePath: { eq: "pernil.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ComboB: file(relativePath: { eq: "combob.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ComboC: file(relativePath: { eq: "comboc.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ComboA: file(relativePath: { eq: "comboa.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      MenuMain: file(relativePath: { eq: "menu_main.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `

export default function useStaticImage(itemId: MenuItemId) {
  const srcData = useStaticQuery(STATIC_IMAGES)[itemId] ?? useStaticQuery(STATIC_IMAGES)['BreadHam']
  return srcData.childImageSharp.fluid;
}