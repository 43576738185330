import moment from 'moment';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';

import { useAppCart, useCartDispatchers } from '../../reducers';
import useOpenScreen from '../../hooks/useOpenScreen';
import useDetectDevice from '../../helpers/useDetectDevice';
import { navigateToHome } from '../../helpers/navigation';
import WhatsAppIcon from '../icons/WhatsApp';

import SentModal from './SentModal';
import EmptyCart from './EmptyCart';
import CartMenuSection from './sections/CartMenuSection';
import CustomerSection, { PickupOption, PaymentOption } from './sections/CustomerSection';
import { getWhatsAppMessage } from '../../helpers/utils';

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});

export default function CheckoutScreen(): JSX.Element {
  const { isMobile } = useDetectDevice();
  const { dispatchClearCart } = useCartDispatchers();
  const { setOpenScreen } = useOpenScreen();
  const cart = useAppCart();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, handleDateChange] = useState(moment());
  const [name, setName] = useState('');
  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [lastname, setLastname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pickupOption, setPickupOption] = useState(PickupOption.Delivery);
  const [paymentOption, setPaymentOption] = useState(PaymentOption.Transference);

  if (cart.items.length === 0) {
    return <EmptyCart />;
  }

  const additionalStyles = isMobile ? {maxWidth: '100vw'} : {};

  const handleSubmitClick = () => {
    if (!name || !lastname) {
      setHasFormErrors(true);
      return;
    }
    // new line => [%0A]
    const message = getWhatsAppMessage(
      cart,
      name,
      lastname,
      phoneNumber,
      pickupOption === PickupOption.Delivery,
      selectedDate,
      paymentOption
    );
    setHasFormErrors(false);
    setIsModalOpen(true);
    window.location.href=`https://wa.me/+50768625006?text=${message}`;
  };

  const handleFinalizeClick = (): void => {
    dispatchClearCart();
    setIsModalOpen(false);
    navigateToHome(setOpenScreen);
  };

  const handleReturnClick = (): void => {
    setIsModalOpen(false);
  }

  return (
    <div style={{padding: '10px',fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', justifyContent: ''}}>
      <Grid container spacing={3} style={additionalStyles}>
        <CartMenuSection cart={cart} />
        <CustomerSection
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          name={name}
          onNameChange={setName}
          lastname={lastname}
          onLastnameChange={setLastname}
          phoneNumber={phoneNumber}
          onPhoneNumberChange={setPhoneNumber}
          pickupOption={pickupOption}
          setPickupOption={setPickupOption}
          paymentOption={paymentOption}
          setPaymentOption={setPaymentOption}
          hasFormErrors={hasFormErrors}
        />
      </Grid>
      <Grid container style={{marginTop: '30px', marginBottom: '50px', display : 'flex', justifyContent: 'center'}}>
        <ThemeProvider theme={theme}>
          <Button variant="contained" color="primary" style={{margin: 1, color: 'white'}} startIcon={<WhatsAppIcon />} onClick={handleSubmitClick}>
            Enviar pedido por WhatsApp
          </Button>
        </ThemeProvider>
      </Grid>
      <SentModal
        isOpen={isModalOpen}
        onFinalizeClick={handleFinalizeClick}
        onReturnClick={handleReturnClick}
      />
    </div>
  );
}
