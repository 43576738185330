import React from 'react';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import {StyleObject, MenuItemId} from '../../../types/types'
import useOpenScreen from '../../hooks/useOpenScreen';
import useStaticImage from '../../helpers/useStaticImage';
import useDetectDevice from '../../helpers/useDetectDevice';
import {navigateToIndividualMenu, navigateToComboMenu} from '../../helpers/navigation';

const STYLES: StyleObject = {
  container: {},
  image: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
  },
  imgMobile: {
    height: '80%',
    width: '80%'
  },
  imgDesk: {
    height: '50%',
    width: '50%'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    marginTop: '50px',
  },
  buttons: {
    marginTop: '20px',
  },
  title: {fontFamily: 'Antojitography', fontSize: 'xx-large'},
}

export default function EmptyCart (): JSX.Element {
  const {setOpenScreen} = useOpenScreen();
  const emptyCart = useStaticImage(MenuItemId.EmptyCart);
  const { isMobile } = useDetectDevice();

  const handeOpenIndividualMenu = (): void => {
    navigateToIndividualMenu(setOpenScreen);
  };

  const handeOpenComboMenu = (): void => {
    navigateToComboMenu(setOpenScreen);
  };

  const additionalStyles = isMobile ? STYLES.imgMobile : STYLES.imgDesk

  return (
    <div style={STYLES.container} role="presentation">
      <img src={emptyCart.src} alt="Logo" style={{...STYLES.image, ...additionalStyles}} />
      <div style={STYLES.title}>
        <span style={{...STYLES.text, ...STYLES.center}}>
          Tu Carrito esta Vacio
        </span>
        <ButtonGroup
          variant="text"
          color="secondary"
          aria-label="text secondary button group"
          style={{...STYLES.buttons, ...STYLES.center}}
        >
          <Button onClick={handeOpenIndividualMenu}>Ir a Menu</Button>
          <Button onClick={handeOpenComboMenu}>Ir a Combos</Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
